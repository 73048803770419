"use client";
import { type FC } from "react";
import classNames from "classnames";
import Image from "next/image";
import { CollectionData } from "logic/DashboardModule";
import TokenIcon from "components/icons/TokenIcon";
import { BasicProps } from "components/types/BasicProps";

type Props = BasicProps & {
  index: number;
  collectionData: CollectionData;
  isLastOne: boolean;
};

export const CollectionItem: FC<Props> = (props) => {
  const {
    className,
    index,
    collectionData: {
      totalNftsLocked,
      name,
      image,
      tvl,
      minimumVal,
      maximumVal,
      currency,
    },
    isLastOne,
  } = props;

  return (
    <>
      <tr
        className={classNames(
          className || "",
          !isLastOne && "border-b border-b-[#FFF7F7]"
        )}
      >
        <td className="pb-3 pt-2 pr-4 flex gap-3">
          <div className="w-[53px] h-[53px] flex items-center justify-center">
            <Image
              className="rounded-lg h-full object-cover"
              src={image}
              width={53}
              height={53}
              alt={`collection ${name}`}
            />
          </div>
          <div className="flex flex-col justify-center">
            <p className="text-xs font-medium">{index}</p>
            <p className="font-bold">{name.toUpperCase()}</p>
          </div>
        </td>
        <td className="pt-2 pb-3 text-center align-bottom">
          {totalNftsLocked}
        </td>
        <td className="pt-2 pb-3 text-center align-bottom">
          <div className="flex justify-center items-center gap-1">
            <span>
              {tvl !== "0" ? currency.formatAmount(BigInt(tvl)) : "0"}
            </span>
            <TokenIcon currency={currency} className="w-4 h-4 -mt-0.5" />
          </div>
        </td>
        <td className="pt-2 pb-3 text-center align-bottom">
          <div className="flex justify-center items-center gap-1">
            <span>
              {minimumVal !== "0"
                ? currency.formatAmount(BigInt(minimumVal))
                : "0"}{" "}
              -{" "}
              {maximumVal !== "0"
                ? currency.formatAmount(BigInt(maximumVal))
                : "0"}
            </span>
            <TokenIcon currency={currency} className="w-4 h-4 -mt-0.5" />
          </div>
        </td>
      </tr>
    </>
  );
};
